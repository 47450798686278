import React from 'react';
import "../../App.css";
import DarwinnImg from "../../Images/Title.svg";
import "../header/header.css"

function Header() {
  return (
    <div className="headerWrap">
        <img src={DarwinnImg} alt="Darwinn" className="darwinnLogo"/>
        
    </div>
  );
}

export default Header;
