import React from 'react';
import DeskLampOpen from "../../Images/DeskLampOff.svg";
import "../intro/intro.css";
import Typing from "react-typing-animation";
import Fade from "../Animations/Fadein"
import "../intro/intro.mobile.css"

function Intro() {
  return (
    <div className="introWrap">
      <div className="introInnerWrap">
        <Typing speed={60} cursor="|" hideCursor={true}>
        <p className="IntroTxt1">You're still needing a website...?</p>
        </Typing>
        <div className="deskLampWrap">
            <img src={DeskLampOpen} alt="no img.." className="deskLampOpen" />
         <Fade>
            <p className="IntroTxt2">Darwinn can take care of that for you.</p>
          </Fade>
        </div >
        </div>
    </div>
  );
}

export default Intro;