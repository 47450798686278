import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Header from "../src/components/header/header"

ReactDOM.render(

  <React.StrictMode>
      <Header />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


serviceWorker.unregister();
