import React from 'react';
import Intro from "../src/components/intro/intro"
import BottomService from "../src/components/intro/serviceSelection"
import "../src/App.css"

function App() {
  return (
    <div className="App">
      <Intro />
      <BottomService />
    </div>
  );
}

export default App;
