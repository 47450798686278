import React from 'react';
import '../../App.css';
import "../../components/intro/intro.css"
import ServiceImg from "../../Images/services.svg"
import ContactImg from "../../Images/Contact.svg"

function bottomService() {
  return (
    <div className="BottomWrap">
      <div className="introBtmFlex">
      <img src={ServiceImg} alt="none"  className="mainButton1"/>
        <img src={ContactImg} alt="none" className="mainButton2"/>
      </div>
    </div>
  );
}

export default bottomService;
